<template>
    <div class="slider" :class="'slider--layout_' + layout" :style="'--slide-gap:' + gap + 'px'">
        <span class="slider-controls" v-if="controls && count > inView">
            <span class="slide-prev" :class="{'is-disabled': activeIdx <= 0}" @click.stop="prev"><inline-svg :src="require('@/assets/images/ic_back.svg')" height="20" /></span>
            <span class="slide-next" :class="{'is-disabled': activeIdx >= (count-inView)}" @click.stop="next"><inline-svg :src="require('@/assets/images/ic_next.svg')" height="20" /></span>
        </span>
        <div class="slider-wrap" :style="'--max-width:' + maxWidth +';--min-width:' + minWidth +';'" v-touch:swipe.left="next" v-touch:swipe.right="prev">
            <slot />
        </div>
        <span v-if="pagination" class="slider-pagination">
            <span v-for="i in count" :key="i" class="slider-bullet" :class="{'is-active': (i - 1) === activeIdx}" @click.stop="this.activeIdx = i - 1;_goTo()"></span>
        </span>
    </div>
</template>

<script>
export default {
	name: "Slider",
	data() {
		return {
			wrap: false,
			activeIdx: 0,
			count: 0,

			itemWidth: 0,
			items: [],
			inView: 1
		};
	},
	props: {
		controls: {
			type: Boolean,
			default: true
		},
		pagination: {
			type: Boolean,
			default: true
		},
		gap: {
			type: Number,
			default: 16
		},
		maxWidth: {
			type: [ Number, String ],
			default: '100%'
		},
		minWidth: {
			type: [ Number, String ],
			default: '288px'
		},
		layout: {
			type: [ Number, String ],
			default: 'default'
		}
	},
    computed:{
		isSafari: function () {
			return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		}
    },
	methods: {
		prev: function () {
			this.activeIdx--;
			if ( this.activeIdx < 0 ) this.activeIdx = this.count;
			this._goTo();
		},
		next: function () {
			this.activeIdx++;
			if ( this.activeIdx >= this.count ) this.activeIdx = 0;
			this._goTo();
		},

		_goTo: function ( anim = true ) {
			let pos = (this.activeIdx * this.itemWidth);
			pos += ((this.activeIdx) * this.gap);

            let start = this.wrap.scrollLeft,
                duration = 300,
                wrap = this.wrap,
                diff = (pos - start),
                startTstamp, prevTstamp,
                done = false, koe;

			function customScrollTo( timestamp ) {

				if ( startTstamp === undefined ) {
					startTstamp = timestamp;
				}

				const elapsed = timestamp - startTstamp;
				if ( prevTstamp !== timestamp ) {
					koe = Math.min(1, elapsed / duration);
					wrap.scrollLeft = start + Math.round((diff * koe));
					prevTstamp = timestamp;
				}

				if ( !done && elapsed < duration ) {
					requestAnimationFrame(customScrollTo);
				}
			}

			if( anim ) {
				requestAnimationFrame(customScrollTo);
			}
			else {
				wrap.scrollLeft = pos;
            }
		},
		_init: function () {
			this.wrap = this.$el.querySelector('.slider-wrap');
			this.items = Array.prototype.slice.call(this.wrap.children);
			this.count = this.items.length;
			this.itemWidth = parseFloat(this.wrap.children[ 0 ].offsetWidth);
			this.inView = Math.max(1, Math.round(this.$el.offsetWidth / (this.itemWidth + this.gap)));
			this._goTo();
		}
	},
	mounted() {
		window.onresize = this._init;
		this._init();

	},
	updated() {
		this.$nextTick(()=> this._init());
        // setTimeout( this._init, 100);
	}
};
</script>

<style>
.slider {
    position: relative;
}

.slider-controls {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 16px;
}

.slider-controls span {
    border-radius: 50%;
    background: var(--color-grey-60);
    display: inline-flex;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
    transition: background-color .2s ease-out;

    &:hover{
        background: var(--color-primary);
    }
}

.slider-controls span.is-disabled {
    pointer-events: none;
    opacity: .5;
}

.slider-pagination {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    column-gap: 6px;
    z-index: 15;
}

.slider-bullet {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .5);
    border: 1px solid #DDD;
    cursor: pointer;
}

.slider-bullet.is-active {
    background: rgba(255, 255, 255, 1);
}

.slider-controls span svg {
    fill: var(--color-white);
}

.slider-controls span + span {
    margin-left: 4px;
}

.slider .slider-wrap {
    overflow: hidden;
    display: flex;
    align-items: stretch;
    /*column-gap: var(--slide-gap, 20px);*/
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
}


.slider-wrap > * {
    flex: 1 0 100%;
    margin-top: 0 !important;
    max-width: var(--max-width, 100%);
    min-width: var(--min-width, 288px);
}
.slider-wrap > img,
.slider-wrap > picture {
    object-fit: cover;
    object-position: center;
}
.slider-wrap .block-video{
    aspect-ratio: 640 / 360;
    height: auto !important;
    /*position: static !important;*/
}
.slider-wrap .block-video iframe{
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 640 / 360;
}

.slider-wrap > .slide + .slide{
    margin-left: var(--slide-gap,16px);
}

@media (max-width: 1023px){
    .slider .slider-wrap {
        margin-left: -16px;
        margin-right: -16px;
    }
    .slider-wrap > .slide{
        margin-left: var(--slide-gap,16px);
    }
}

.slider--layout_controlsinview .slider-controls {
    position: static;
}

.slider--layout_controlsinview .slider-controls .slide-next,
.slider--layout_controlsinview .slider-controls .slide-prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1.5);
    z-index: 50;
    /*background: var(--color-primary, #CCC);*/
}

.slider--layout_controlsinview .slider-controls .slide-next {
    right: 16px;
}

.slider--layout_controlsinview .slider-controls .slide-prev {
    left: 16px;
}
</style>